
// _base.scss imports _custom.scss (variables and mixins) and other partials.
@import "base";

// This file defines Grid & Layout for larger devices eg. Tablet, Desktop PC.

/* Quickfix: We need to comensate the negative margin on the group title */
.item-list[class*=" layout-"] > h3{
margin-left:$grid;
}

@media only screen and (min-width: $tablet_landscape_start){
  /* Page Layout */
  .page-content-column{
    float:left;
  }

  //
  // *********** Page-Grid ***********
  //
  .pane-inner,
  .menu-wrapper,
  .region-footer > .region-inner{
    margin-left: $grid;
    margin-right: $grid;
  }

  /* -- Only RIGHT Grid */
  .SELECTOR{
    padding-right: $grid;
  }

  /* -- NO GRID (reset) */
  .region-navigation-bar .block,
  .panel-pane .block-inner,
  .region-footer > .region-inner{
    margin:0;
  }

  /* -- Sidebars */
  .two-sidebars .content-inner {
      margin-left: $sidebar_first_width;
      margin-right: $sidebar_second_width;
  }
  .sidebar-first .content-inner{
    margin-left: $sidebar_first_width;
    margin-right: 0;
  }
  .region-sidebar-first {
      margin-left: -100%;
      width: $sidebar_first_width;
      float:left;
  }
  .sidebar-second .content-inner{
    margin-right: $sidebar_second_width;
    margin-left: 0;
  }
  .region-sidebar-second {
    margin-left: -$sidebar_second_width;
    width: $sidebar_second_width;
    float:left;
  }
  /* -- -- Grid */
  .region-sidebar-first > .region-inner{
    margin-right:$grid;
  }
  .region-sidebar-second > .region-inner{
    margin-left:$grid;
  }

  /* Block behavior (by region) */
  @if $show_footer_menu_vertical == true{
    /* FUCK OFF */
    .region-footer .block{
      float:left;
      width:25%;
      box-sizing:border-box;
      &:nth-child(4n-3){
        @include webksgrid(4, first, normal, inner);
      }
      &:nth-child(4n-2){
        @include webksgrid(4, next-to-first, normal, inner);
      }
      &:nth-child(4n-1){
        @include webksgrid(4, next-to-last, normal, inner);
      }
      &:nth-child(4n){
        @include webksgrid(4, last, normal, inner);
      }
    }
  }@else{
    .region-footer .block{
      // Attention! This currently only works with 4 Blocks. So if you need more blocks per column,
      // you maybe better use mini-panels to group the blocks.
      &.block-menu-menu-footer-menu{
        width:auto;
        text-align:right;
        float:none;
        clear:both;
      }
    }
  }
}

// "Small"
@media only screen and (max-width: $tablet_portrait_start){
  .layout-small-2-cols,
  .layout-small-3-cols{
    margin-left: -$grid;
    > .layout-child,
    > .field-items > .field-item,
    > ul > li.views-row{
      float:left;
      box-sizing:border-box;
    }
  }
  /*
   * -- One Col
   */
  // no styling necessary

  /*
   * -- Two Cols
   */

  // Width
  .layout-small-2-cols > .layout-child,
  .layout-small-2-cols > .field-items > .field-item,
  .view .item-list.layout-small-2-cols > ul > li.views-row{ width:50%; padding-left:$grid; }

  // Clearing (first element of new row)
  .layout-small-2-cols > .layout-child:nth-child(2n-1),
  .layout-small-2-cols > .field-items > .field-item.odd,
  .view .item-list.layout-small-2-cols > ul > li.views-row.views-row-odd{ clear:left; }

  /*
   * -- Three Cols
   */
  // Width
  .layout-small-3-cols > .layout-child,
  .layout-small-3-cols > .field-items > .field-item,
  .view .item-list.layout-small-3-cols > ul > li.views-row{ width:33.333%; padding-left:$grid; }

  // Clearing (first element of new row)
  .layout-small-3-cols > .layout-child:nth-child(3n-2),
  .layout-small-3-cols > .field-items > .field-item:nth-child(3n-2),
  .view .item-list.layout-small-3-cols > ul > li.views-row:nth-child(3n-2){ clear:left; }
}

// "Medium"
@media only screen and (min-width: $tablet_portrait_start) and (max-width: $desktop_floating_breakpoint){
  .layout-medium-2-cols,
  .layout-medium-3-cols,
  .layout-medium-4-cols{
    margin-left: -$grid;
    > .layout-child,
    > .field-items > .field-item,
    > ul > li.views-row{
      float:left;
      box-sizing:border-box;
    }
  }
  /*
   * -- Two Cols
   */
  // Width
  .layout-medium-2-cols > .layout-child,
  .layout-medium-2-cols > .field-items > .field-item,
  .view .item-list.layout-medium-2-cols > ul > li.views-row{ width:50%; padding-left:$grid; }

  // Clearing (first element of new row)
  .layout-medium-2-cols > .layout-child:nth-child(2n-1),
  .layout-medium-2-cols > .field-items > .field-item.odd,
  .view .item-list.layout-medium-2-cols > ul > li.views-row.views-row-odd{ clear:left; }

  /*
   * -- Three Cols
   */
  // Width
  .layout-medium-3-cols > .layout-child,
  .layout-medium-3-cols > .field-items > .field-item,
  .view .item-list.layout-medium-3-cols > ul > li.views-row{ width:33.333%; padding-left:$grid; }

  // Clearing (first element of new row)
  .layout-medium-3-cols > .layout-child:nth-child(3n-2),
  .layout-medium-3-cols > .field-items > .field-item:nth-child(3n-2),
  .view .item-list.layout-medium-3-cols > ul > li.views-row:nth-child(3n-2){ clear:left; }

/*
   * -- Four Cols
   */
  // Width
  .layout-medium-4-cols > .layout-child,
  .layout-medium-4-cols > .field-items > .field-item,
  .view .item-list.layout-medium-4-cols > ul > li.views-row{ width:25%; padding-left:$grid; }

  // Clearing (first element of new row)
  .layout-medium-4-cols > .layout-child:nth-child(4n-3),
  .layout-medium-4-cols > .field-items > .field-item:nth-child(4n-3),
  .view .item-list.layout-medium-4-cols > ul > li.views-row:nth-child(4n-3){ clear:left; }
}

// "Large"
@media only screen and (min-width: $desktop_floating_breakpoint){
  .layout-large-2-cols,
  .layout-large-3-cols,
  .layout-large-4-cols,
  .layout-large-5-cols,
  .layout-large-6-cols{
    margin-left: -$grid;
    > .layout-child,
    > .field-items > .field-item,
    > ul > li.views-row{
      float:left;
      box-sizing:border-box;
    }
  }

  /*
   * -- Two Cols
   */
  // Width
  .layout-large-2-cols > .layout-child,
  .layout-large-2-cols > .field-items > .field-item,
  .view .item-list.layout-large-2-cols > ul > li.views-row{ width:50%; padding-left:$grid; }

  // Clearing (first element of new row)
  .layout-large-2-cols > .layout-child:nth-child(2n-1),
  .layout-large-2-cols > .field-items > .field-item.odd,
  .view .item-list.layout-large-2-cols > ul > li.views-row.views-row-odd{ clear:left; }

  /*
   * -- Three Cols
   */
  // Width
  .layout-large-3-cols > .layout-child,
  .layout-large-3-cols > .field-items > .field-item,
  .view .item-list.layout-large-3-cols > ul > li.views-row{ width:33.333%; padding-left:$grid; }

  // Clearing (first element of new row)
  .layout-large-3-cols > .layout-child:nth-child(3n-2),
  .layout-large-3-cols > .field-items > .field-item:nth-child(3n-2),
  .view .item-list.layout-large-3-cols > ul > li.views-row:nth-child(3n-2){ clear:left; }

/*
   * -- Four Cols
   */
  // Width
  .layout-large-4-cols > .layout-child,
  .layout-large-4-cols > .field-items > .field-item,
  .view .item-list.layout-large-4-cols > ul > li.views-row{ width:25%; padding-left:$grid; }

  // Clearing (first element of new row)
  .layout-large-4-cols > .layout-child:nth-child(4n-3),
  .layout-large-4-cols > .field-items > .field-item:nth-child(4n-3),
  .view .item-list.layout-large-4-cols > ul > li.views-row:nth-child(4n-3){ clear:left; }

/*
   * -- Five Cols
   */
  // Width
  .layout-large-5-cols > .layout-child,
  .layout-large-5-cols > .field-items > .field-item,
  .view .item-list.layout-large-5-cols > ul > li.views-row{ width:20%; padding-left:$grid; }

  // Clearing (first element of new row)
  .layout-large-5-cols > .layout-child:nth-child(5n-4),
  .layout-large-5-cols > .field-items > .field-item:nth-child(5n-4),
  .view .item-list.layout-large-5-cols > ul > li.views-row:nth-child(5n-4){ clear:left; }

/*
   * -- Six Cols
   */
  // Width
  .layout-large-6-cols > .layout-child,
  .layout-large-6-cols > .field-items > .field-item,
  .view .item-list.layout-large-6-cols > ul > li.views-row{ width:16.666%; padding-left:$grid; }

  // Clearing (first element of new row)
  .layout-large-6-cols > .layout-child:nth-child(6n-5),
  .layout-large-6-cols > .field-items > .field-item:nth-child(6n-5),
  .view .item-list.layout-large-6-cols > ul > li.views-row:nth-child(6n-5){ clear:left; }

}
// Desktop and Tablet Portrait (circa same content width)
@media only screen and (min-width: $tablet_portrait_start) and (max-width: $tablet_portrait_end), (min-width:$desktop_floating_breakpoint) {
  /* Content */
  /* -- Two Cols */
  .col-left{ box-sizing:border-box; float:left; width:50%; padding-right:$grid / 2; }
  .col-right{ box-sizing:border-box; float:left; width:50%;  padding-left:$grid / 2; }

  /* -- FLOATS */
  // Using body to make it more important then global.layout.css definitions which is implemented later in cause of technical trouble
  body .float-left,
  body .floatleft,
  body .left{ display:block; float:left; margin:5px ($grid * 2) $grid_vertical 0; }
  body .float-right,
  body .floatright,
  body .right{ display:block; float:right; margin:5px 0 $grid_vertical ($grid * 2); }

  /* Views */
  /* -- Image */
  .view .item-list li.views-row .view-image{ float:left; margin: 5px $grid 3px 0; }
  /* -- Views Row with Image Column */
  /* -- -- Links anliegend */
  .view .item-list li.image-column > .inner{ padding-left: $view_image_width + $grid; }
  .view .item-list li.image-column > .inner .view-image{ width: $view_image_width; overflow:hidden; margin-left: - ($view_image_width + $grid); margin-right:0; padding:0; }
  /* -- -- Links Abstand (z. B. bei .box-style) */
  .view .item-list li.image-column.left-space > .inner{ padding-left: $view_image_width + $grid + $thin_grid; }
  .view .item-list li.image-column.left-space > .inner .view-image{ width: $view_image_width; overflow:hidden; margin-left: - ($view_image_width + $grid - ($thin_grid / 2)); margin-right:0; padding:0; }

  /* -- More width image column */
  .view .item-list li.image-column.image-column--width > .inner{ padding-left: 300px + ($grid * 2); }
  .view .item-list li.image-column.image-column--width > .inner .view-image{ width: 300px; margin-left: - (300px + ($grid * 2)); }

  /* Panels */
  /* -- 2 Cols embed */
  .region-two-cols-embed-embed{
    float:right;
    width:33.333%;
    margin-left:$grid;
    margin-bottom:15px;
    position:relative;
    z-index:2;
  }

  /* Custom Floats */
  .block-region-content .webform-component--kontakt,
  .block-region-content .webform-component--ihre-nachricht{
    float:left;
    width:48%;
    box-sizing:border-box;
    margin:0;
  }
  .block-region-content .webform-component--kontakt{
    margin-right:4%;
  }

  /* Panel width override (for non equal columns) */
  .webks-panel .region-three-25-25-50-first,
  .webks-panel .region-three-25-25-50-second,
  .webks-panel .region-three-25-50-25-first,
  .webks-panel .region-three-25-50-25-third,
  .webks-panel .region-three-50-25-25-second,
  .webks-panel .region-three-50-25-25-third{
    width:25%;
  }
  .webks-panel .region-three-25-25-50-third,
  .webks-panel .region-three-25-50-25-second,
  .webks-panel .region-three-50-25-25-first{
    width:50%;
  }
  .webks-panel .region-two-66-33-second,
  .webks-panel .region-two-33-66-first{
    width:33.333%;
  }
  .webks-panel .region-two-66-33-first,
  .webks-panel .region-two-33-66-second{
    width:66.666%;
  }
}
// Opposite of the mq above
@media only screen and (max-width: $smartphone_end) and (min-width: $tablet_landscape_start), (max-width:$desktop_floating_breakpoint) {

}

@media only screen and (max-width: $smartphone_end){
   /* Small Screen - no floating */
  .webks-panel .page-panel-sidebar-first,
  .webks-panel .page-panel-sidebar-second,
  .webks-panel .page-panel-content{
    width:auto !important;
    float:none !important;
    clear:both !important;
  }
}
@media only screen and (min-width: $tablet_portrait_start){
  /* Panel PAGE Layouts Custom Breakpoints & Widths */
  #page{
    // make this all more important..
    /* -- Two "Sidebars" (Converted to one Sidebar) */
    .webks-panel .region-page-25-50-25-top-left{
      width:auto;
      float:none;
    }
    .webks-panel .page-panel-sidebar-first{
      width:35%;
      float:left;
    }
    .webks-panel .region-three-25-50-25-second.page-panel-content{
      width:65%;
      float:left;
    }
    .webks-panel .page-panel-sidebar-second{
      width:65%;
      margin-left:35%;
      float:none;
      clear:left;
    }
    .webks-panel .region-page-25-50-25-top-right{
      width:auto;
      float:none;
    }
    /* -- One "Sidebar (Left)" */
    .webks-panel.page-25-50,
    .page-panel-content{
      .region-three-25-50-25-second{
        width:65%;
        float:left;
      }
      .region-page-25-50-top-left{
        width:65.56561%;
        float:left;
      }
      .region-page-25-50-top-right{
        width:34.43439%;
        float:left;
      }
    }
    /* -- One "Sidebar (Left) - WITHOUT top-right region" */
    .webks-panel.page-25-50-full{
      .region-three-25-50-25-second{
        width:65%;
        float:left;
      }
      .page-panel-content{
        width:65%;
        float:left;
        .page-panel-content{
          width:auto;
        }
      }
    }
  }
}
@media only screen and (min-width: $tablet_landscape_start){
  /* Panel PAGE Layouts Custom Breakpoints & Widths */
  /* -- Two "Sidebars" */
  #page{
    // make this all more important..

    .webks-panel .region-page-25-50-25-top-left{
      width:80%;
      float:left;
    }
    .webks-panel .page-panel-sidebar-first{
      width:20%;
      float:left;
    }
    .webks-panel .region-three-25-50-25-second.page-panel-content{
      width:60%;
    }
    .webks-panel .page-panel-sidebar-second{
      width:20%;
      margin-left:0;
      float:left;
      clear:none;
    }
    .webks-panel .region-page-25-50-25-top-right{
      width:20%;
      float:left;
    }
    /* -- One "Sidebar (Left)" */
    .webks-panel.page-25-50,
    .page-panel-content{
      .region-three-25-50-25-second{
        width:80%;
      }
      .region-page-25-50-top-left{
        width:75.56561%;
      }
      .region-page-25-50-top-right{
        width:24.43439%;
      }
    }
    /* -- One "Sidebar (Left) with header row above the left sidebar (NEW IP default layout) */
    .webks-panel.page-layout-ip-default{
      .region-three-25-75-second{
        width:80%;
      }
    }
    /* -- One "Sidebar (Left) - WITHOUT top-right region" */
    .webks-panel.page-25-50-full{
      .region-three-25-50-25-second{
        width:80%;
      }
      .page-panel-content{
        width:80%;
        .page-panel-content{
          width:auto;
        }
      }
    }
  }
}
